<template>
    <modal ref="modalAgregarEquipoModelo" titulo="Cargar equipo de otra oportunidad" adicional="Añadir" @adicional="agregarEquipoMOdelo">
            <ValidationObserver ref="validator">
        <div class="row justify-content-center m-3 f-15">
                <div class="col-9 my-3">
                    <p class="input-label-top">Oportunidad</p>
                    <ValidationProvider v-slot="{ errors }" name="oportunidad" rules="required">
                        <el-select v-model="idOportunidad" @change="getEquipos" filterable placeholder="Seleccionar oportunidad" size="small" class="w-100">
                            <el-option v-for="item in select_projects" :key="item.id" :label="item.nombre" :value="item.id" />
                        </el-select>
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </div>
                <div class="col-9 my-3">
                    <p class="input-label-top">Equipo modelo</p>
                    <ValidationProvider v-slot="{ errors }" name="equipo modelo" rules="required">
                        <el-select v-model="modeloIndex" filterable placeholder="Seleccionar equipo modelo" size="small" class="w-100">
                            <el-option v-for="(item, index) in select_opportunity_equipos_modelos" :key="`modelo-${index}`" :label="item.nombre" :value="index" />
                        </el-select>
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </div>
        </div>
            </ValidationObserver>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data(){
        return {
            idOportunidad: '',
            modeloIndex: ''
        }
    },
    computed: {
        ...mapGetters({
            select_projects: 'selects/selects/select_projects',
            select_opportunity_equipos_modelos: 'selects/selects/select_opportunity_equipos_modelos',
        }),
    },
    methods: {
        ...mapActions({
            Action_get_opportunity_equipos: 'oportunidades/oportunidades/Action_get_opportunity_equipos',
            Action_get_select_opportunity_equipos_modelos: 'selects/selects/Action_get_select_opportunity_equipos_modelos',
            Action_add_opportunity_equipo: 'oportunidades/oportunidades/Action_add_opportunity_equipo',
            Action_get_select_projects: 'selects/selects/Action_get_select_projects',
        }),
        async toggle(){
            this.$refs.validator.reset()
            this.$refs.modalAgregarEquipoModelo.toggle()
            this.Action_get_select_projects()
        },
        async getEquipos(idOps){
            this.$refs.validator.reset()
            if ( idOps ) await this.Action_get_select_opportunity_equipos_modelos(idOps)
        },
        async agregarEquipoMOdelo(){
            const valid = await this.$refs.validator.validate();
            if (valid) {
                let data = this.select_opportunity_equipos_modelos[this.modeloIndex]
                const params = {
                    id: this.$route.params.id,
                    payload: { id_equipo_modelo: data.id, tabla: data.tabla, id_ops: data.id_ops}
                }
                await this.Action_add_opportunity_equipo(params)
                this.idOportunidad = ''
                this.modeloIndex = ''
                this.$refs.modalAgregarEquipoModelo.toggle()
                await this.Action_get_opportunity_equipos(this.$route.params.id);
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
